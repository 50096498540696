import { UserTypes } from "app/User/types"
import { privateApi, publicApi } from "utils/api"

/**
 * Fetch a list of accounts
 */
type GetUsersResponse = {
  result: string
  count?: number
  users?: UserTypes[]
  error?: unknown
}

type GetAPIUsersResponse = {
  result: string
  count: number
  Users: any[]
}

type CreateOrPatchUsersResponse = {
  result: string
  user?: UserTypes
  error?: unknown
}

type CreatePatchOrDeleteAPIUsersResponse = {
  result: string
  user: UserTypes
}

type DeleteUserResponse = {
  result: string
  user?: UserTypes
  error?: unknown
}

type UpdatePasswordResponse = {
  result: string
  error?: unknown
}

type UpdatePasswordAPIResponse = {
  result: string
}

const getUsers = async (): Promise<GetUsersResponse> => {
  try {
    const {
      data: { result, count, users },
    } = await privateApi().get<GetAPIUsersResponse>(`/CmsUsers/0`)
    return {
      count,
      result,
      users,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const createUser = async (
  args,
  accountID
): Promise<CreateOrPatchUsersResponse> => {
  try {
    const {
      data: { result, user },
    } = await privateApi().post(`/CmsUsers/${accountID}`, args)

    return {
      result,
      user,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const saveUser = async (args, userID): Promise<CreateOrPatchUsersResponse> => {
  try {
    const {
      data: { result, user },
    } = await privateApi().patch<CreatePatchOrDeleteAPIUsersResponse>(
      `/CmsUsers/${userID}`,
      args
    )

    return {
      result,
      user,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const deleteUser = async (userID): Promise<DeleteUserResponse> => {
  try {
    const {
      data: { result, user },
    } = await privateApi().delete<CreatePatchOrDeleteAPIUsersResponse>(
      `/CmsUsers/${userID}`
    )
    return {
      result,
      user,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const updateUserPassword = async (
  { token, password },
  userID
): Promise<UpdatePasswordResponse> => {
  try {
    const {
      data: { result },
    } = await publicApi(token).patch<UpdatePasswordAPIResponse>(
      `/user/${userID}/password`,
      { password, id: userID }
    )

    return {
      result,
    }
  } catch (e) {
    return {
      result: "error",
      error: e,
    }
  }
}

const resetPassword = async (body) => {
  try {
    const response = await publicApi().post(`/user/reset/public`, body)
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export {
  getUsers,
  createUser,
  saveUser,
  deleteUser,
  updateUserPassword,
  resetPassword,
}
